<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login -->
      <b-card class="mb-0">
        <b-link class="brand-logo mb-3 mt-3">
          <b-img
            src="@/assets/images/logo/CuidoLogo.png"
            height="57.5px"
          />
        </b-link>

        <b-card-title class="mb-2 text-center">
          Fazer Login
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Para entrar na sua conta CUIDO
          <br>
          digite abaixo seu e-mail e senha:
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Digite aqui seu e-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="e-mail"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder=""
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Digite aqui sua senha</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="senha"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder=""
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <alert-message :status="status" />

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              class="mt-2"
              @click="login"
            >
              ENTRAR
            </b-button>

            <div class="mt-2 text-center">
              <b-button
                block
                variant="outline-primary"
                @click="register"
              >
                MEU PRIMEIRO ACESSO
              </b-button>
            </div>

            <div class="mt-2 text-center">
              <b-link
                :to="{name:'password-email'}"
              >
                <u>ESQUECI MINHA SENHA</u>
              </b-link>
            </div>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import AlertMessage from '@/layouts/components/AlertMessage.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BImg,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    AlertMessage,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: {},
      // validation rules
      required,
      email,
      customMessages: {
        pt_BR: {
          fields: {
            'e-mail': {
              email: 'Digite um e-mail válido.',
            },
          },
        },
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    localize(this.$store.getters['cuido/locale'], this.customMessages.pt_BR)
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.ip()
          useJwt.login({
            email: this.userEmail,
            password: this.password,
            client_info: {
              ip_address: this.$store.getters['register/ip'],
            },

          })
            .then(response => {
              if (response.data.data === null) {
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'E-mail e/ou senha incorretos.',
                }
              } else {
                useJwt.setToken(response.data.data.token)
                const ability = {
                  ability: [
                    {
                      action: 'read',
                      subject: 'ACL',
                    },
                    {
                      action: 'read',
                      subject: 'Auth',
                    },
                  ],
                }
                const userData = response.data.data.worker_info
                if (userData.companies_info.length === 0) {
                  ability.ability.push({
                    action: 'read',
                    subject: 'ACL_STOCK',
                  })
                }
                Object.assign(userData, ability)
                const role = { role: 'admin' }
                Object.assign(userData, role)
                localStorage.setItem('userData', JSON.stringify(userData))

                this.$store.commit('login/UPDATE_ROLE', userData.role)
                this.$store.commit('login/UPDATE_NAME', userData.name)
                this.$store.commit('login/UPDATE_EMAIL', userData.email)
                this.$store.commit('login/UPDATE_ONBOARDING', userData.onboarding)

                this.$ability.update(userData.ability)
                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              }
            })
            .catch(e => {
              if (e.response.data.error.code === 401) {
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'E-mail e/ou senha incorretos.',
                }
              } else {
                this.$refs.loginForm.setErrors(e.response.data.error)
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'Ops... Algo deu errado, tente novamente',
                }
              }
            })
        }
      })
    },
    register() {
      this.$store.commit('register/RESET')
      this.ip()
      this.$router.push('register-email')
    },
    async ip() {
      const getJSON = (url, callback) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'json'
        xhr.onload = () => {
          const { status } = xhr
          if (status === 200) {
            callback(null, xhr.response)
          } else {
            callback(status, xhr.response)
          }
        }
        xhr.send()
      }

      getJSON('https://ipinfo.io/json',
        (err, data) => {
          if (err !== null) {
            // console.error(`Something went wrong: ${err}`)
          } else {
            this.$store.commit('register/UPDATE_IP', data.ip)
            this.$store.commit('register/UPDATE_BROWSER', navigator.userAgent)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
